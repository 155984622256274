* {
  box-sizing: border-box;
}

@page {
  size: A4 portrait;
  margin: 2cm 3cm;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

body {
  fill: currentColor;
  padding: 5rem 0;
  @media print {
    padding: 0;
  }
}

address {
  font-style: normal;
  line-height: 1.6rem;
  margin: 0rem 0;
  color: rgba(0, 0, 0, 0.76);
}

// ul {
//   padding: 0;
//   margin: 0;
// }

p,
li {
  line-height: 1.6rem;
  color: rgba(black, 0.76);
}
// p:empty {
//   min-height: 1.6rem;
// }

li {
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.single-blog-main,
.single-blog-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
}

code {
  background: rgba(0, 0, 0, 0.12);
  padding: 1px 3px;
  border-radius: 2px;
}

pre {
  overflow: auto;
  padding: 8px;
  box-sizing: border-box;
  // background: #eee;
  p {
    margin: 0;
    color: inherit;
  }
}

.inline-pre {
  padding: 0 !important;
  background: none !important;
  display: inline !important;
}

img {
  max-width: 100%;
  &[src*='.gif'] {
    width: auto !important;
  }
}

.block-img {
  img {
    display: block;
    margin: 0 auto;
  }
}

[data-oembed] {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 49.06%;
  overflow: hidden;
  margin-top: 2rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.sep {
  color: rgba(0, 0, 0, 0.32);
}

.container {
  margin: 0 auto;
  padding: 0 32px;
  @media (min-width: 880px) {
    max-width: 768px;
    padding: 0;
  }
}

.logo {
  font-size: 1.75rem;
  margin-bottom: 0.35rem;
}

.print-email {
  display: none;
  margin-bottom: 1rem;
  text-decoration: none;
  @media print {
    display: block;
  }
}

.hero-address {
  display: none;
  @media print {
    display: block;
  }
}

.title {
  color: rgba(black, 0.76);
  font-size: 1rem;
}

.year-title {
  font-size: 2.25rem;
  // margin-left: -12px;
  // margin-right: -12px;
}

.nav-outer {
  overflow: auto;
}

.nav-inner {
  white-space: pre;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
}

.nav {
  padding-left: 0;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  @media print {
    display: none;
  }
  li {
    display: inline-block;
    list-style: none;
    margin-left: 24px;
    &.active {
      font-weight: 600;
    }
    &:after {
      content: '/';
      position: relative;
      right: -12px;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}

.hero {
  margin-bottom: 6rem;
  @media print {
    margin-bottom: 2rem;
  }
}

.preview {
  padding: 4px 12px;
  display: inline-block;
  width: 100%;
  @media (min-width: 466px) {
    width: 50%;
  }
  @media (min-width: 767px) {
    width: 33.33%;
  }
  a {
    text-decoration: none;
  }
}

.project-preview-image {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  &.inView {
    opacity: 1;
  }
}

.preview-title {
  margin-bottom: 0rem;
}

.preview-type {
  margin-top: 0.5rem;
  color: rgba(black, 0.32);
}

.works {
  margin-left: -12px;
  margin-right: -12px;
  @media (min-width: 860px) {
  }
}

.year-wrapper {
  padding: 2rem 0;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
}

.preview-type {
  color: rgba(black, 0.8);
  text-transform: capitalize;
}

.preview-image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 62.5%;
  overflow: hidden;
  background: rgba(black, 0.08);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.single-project-title {
  font-size: 0.85rem;
  @media (min-width: 600px) {
    font-size: initial;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.app-footer {
  padding: 5rem 0 3rem 0;
  @media print {
    display: none;
  }
}

.address {
  margin: 3rem 0 5rem 0;
  font-style: normal;
  line-height: 1.6;
}

.address-footer {
  @media (min-width: 580px) {
    text-align: right;
  }
}

.address p {
  color: rgba(0, 0, 0, 0.62);
}

.footer-wrapper {
  @media (min-width: 580px) {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.copyright {
  color: rgba(black, 0.72);
  margin-bottom: 1.5rem;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
}

.social {
  padding-left: 0;
  li {
    display: inline-block;
    list-style: none;
    margin-left: 24px;
    &:after {
      content: '/';
      position: relative;
      right: -12px;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}

.single-project-footer {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-link {
  margin-top: 5rem;
  display: block;
  @media print {
    display: none;
  }
}

.print {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  @media print {
    display: none;
  }
}

.print-top {
  margin-bottom: 3rem;
}

.print-bottom {
  margin-top: 3rem;
}

.print-icon {
  display: inline-block;
  margin-right: 0.5rem;
}

.print-txt {
  margin: 0;
  color: blue;
}

.intro {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 3rem;
  padding-top: 3rem;
}

.intro-web {
  @media print {
    display: none;
  }
}

.skills-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 3rem;
  padding-top: 3rem;
  h2 {
    margin-top: 0;
    margin-bottom: 3rem;
  }
}

// .skills-section {
//   page-break-after: avoid;
// }

.experience-section {
  margin-top: 3rem;
  &:first-of-type {
    margin-top: 0;
  }
}

.skills-section h3 {
  page-break-after: avoid;
}

.intro-print {
  display: none;
  @media print {
    display: block;
  }
}

.about-wrapper {
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

#mugshot {
  margin-left: 2rem;
  float: right;
  max-width: 300px;
}

.blog-wrapper {
  line-height: 3rem;
  padding-left: 0;
  h1 {
    line-height: 1.25;
    margin-bottom: 0;
  }
  h5 {
    margin-top: -0.75rem;
  }
}

.article-preview {
  svg {
    path {
      fill: blue;
    }
  }
  a:visited {
    svg {
      path {
        fill: purple;
      }
    }
  }
}

.article-preview-footer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  h5 {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.external-resource-name {
  display: flex;
  align-items: center;
  h5 {
    margin-left: 6px;
  }
}

.single-work {
  h4 {
    margin-bottom: -10px;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(black, 0.72);
  }
}

.single-blog-main {
  margin-top: 4rem;
}

.splide__list {
  height: auto;
}

.fullscreen-image {
  position: relative;
  br {
    display: none;
  }
  p {
    line-height: 0;
    margin: 0;
  }
  @media (min-width: 720px) {
    margin-left: calc(-100vw / 2 + 51%);
    margin-right: calc(-100vw / 2 + 51%);
  }
  @media (min-width: 1000px) {
    margin-left: calc(-100vw / 2 + 58%);
    margin-right: calc(-100vw / 2 + 58%);
  }
  @media (min-width: 1366px) {
    margin-left: calc(-100vw / 2 + 70%);
    margin-right: calc(-100vw / 2 + 70%);
  }
}

.cp_embed_wrapper {
  position: relative;
  height: 0;
  padding-top: 75%;
  overflow: hidden;
  @media (min-width: 720px) {
    margin-left: calc(-100vw / 2 + 420px);
    margin-right: calc(-100vw / 2 + 420px);
  }

  iframe {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.contact-wrapper {
  label {
    display: block;
  }
  textarea {
    min-height: 250px;
  }
}

.contact-input {
  position: relative;
  padding: 18px 14px;
  border: none;
  border-bottom: 1px solid #999;
  background: #eee;
  &.submit-btn {
    border: none;
    background: #111;
    color: #fff;
    cursor: pointer;
  }
}

.form-group {
  margin-bottom: 3rem;
  label {
    margin-bottom: 0.5rem;
  }
}

.inline-form-group {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0rem;
    margin-left: 0.5rem;
  }
}

.full-width {
  width: 100%;
}

#budget-group {
  display: none;
}

#budget {
  max-width: 108px;
}

.email-send-loader-wrapper {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s ease;
  &.visible {
    opacity: 1;
  }
}

.email-send-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 10px;
  background: #111;
  animation: load-send-email 1s infinite ease-in-out;
}

@keyframes load-send-email {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}

#contact-form {
  margin-top: 3rem;
}

.contact-form-success {
  display: none;
  text-align: center;
}

.legal {
  margin: 7rem 0 2rem 0;
  @media (min-width: 580px) {
    // display: flex;
    // justify-content: flex-end;
  }
  @media print {
    display: none;
  }
}

.legal a {
  color: rgba(0, 0, 0, 0.62);
}

.legal li {
  &:after {
    color: rgba(0, 0, 0, 0.62);
  }
}

#download-app-store-btn {
  max-width: 220px;
  width: 100%;
  img {
    width: 100%;
  }
}

.table-contents-list {
  li {
    margin-bottom: 0;
  }
  br {
    display: none;
  }
}

.highlight {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 0.5rem 1rem;
}

.splide {
  line-height: 0;
  p {
    margin: 0;
  }
}
